import React from 'react';
import { graphql } from 'gatsby';
import _ from 'lodash';

import HomeLayout from '../components/HomeLayout';

import { HTMLContent } from '../components/Content'

import NewsPageTemplate from '../components/NewsPageTemplate'
import { useMetaOverride } from '../lib/hoc/useMeta';

const NewsPage = ({ data, pageContext }) => {
  const defaultPost = _.get(data, 'markdownRemark');
  const post = useMetaOverride('news', defaultPost);

  const items = _.map(_.get(data, 'news.items'), 'data');
  return (
    <HomeLayout headerText={_.get(post, 'frontmatter.description')}>
      <NewsPageTemplate
        contentComponent={HTMLContent}
        title={_.get(post, 'frontmatter.title', '')}
        content={_.get(post, 'html')}
        post={post}
        items={items}
        pageContext={pageContext}
      />
    </HomeLayout>
  )
}

export const newsPageQuery = graphql`
  query NewsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        actionText
        actionLink
        thumbnail {
          publicURL
        }
        thumbnailUrl
        thumbnailAlt
        thumbnailTarget
        thumbnailVisibility
      }
    }
    news: allAirtable(filter: {table: {eq: "News"}, data: {status: {eq: "published"}}}, sort: {order: DESC, fields: data___date_scheduled}) {
      items: nodes {
        id
        data {
          tags
          status
          attachments {
            url
            thumbnails {
              small {
                url
              }
            }
          }
          date_scheduled
          description
          full_copy
          title
          post_type
        }
      }
    }
  }
`

export default NewsPage;
