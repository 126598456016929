/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Grid, Pagination } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';
import { useQueryParam, NumberParam } from 'use-query-params';
import { useStaticQuery, graphql } from 'gatsby';
import moment from 'moment';

import NewsCard1 from './NewsCard1';
import NewsCardFeatured from './NewsCardFeatured';
import NotFound from '../NotFound';


const getLatestNews = (items) => {
  const now = moment().format("YYYY-MM-DD");
  const found = _.find(_.castArray(items || []), item => {
    return _.get(item, 'data.date_scheduled') <= now;
  });
  return found;
}

export const NewsList = props => {
  const rawQuery = typeof window === 'undefined' ? {} : null;
  const newsData = useStaticQuery(graphql`
    query NewsFeaturedQuery {
      data: allAirtable(filter: {table: {eq: "News"}, data: {status: {eq: "published"}}}, sort: {order: DESC, fields: data___date_scheduled}) {
        items: nodes {
          id
          data {
            tags
            status
            attachments {
              url
              thumbnails {
                small {
                  url
                }
              }
            }
            date_scheduled
            description
            full_copy
            title
            post_type
          }
        }
      }
    }
  `);
  const latestNews = getLatestNews(_.get(newsData, 'data.items'));

  const { onClick } = props;
  let { cats } = props;
  cats = _.reject(cats || [], cat => (cat.title === latestNews.data.title && cat.date_scheduled === latestNews.data.date_scheduled))
  const [page, $page] = useQueryParam('page', NumberParam, rawQuery);
  const [pageIndex, $pageIndex] = React.useState(page || 1);
  const [limitParam] = useQueryParam('limit', NumberParam, rawQuery);
  const [limit] = React.useState(limitParam || 5);
  const totalItems = _.get(cats, 'length', 0);

  // reset pagination whever cats changed
  React.useEffect(() => {
    $page(1);
    $pageIndex(1);
  }, [_.get(cats, 'length')]);
  const totalPages = Math.ceil(totalItems / limit);
  return (
    <div className={cx(props.className)}>
      <NewsCardFeatured cat={latestNews} />
      <div className="title text-h3 txt-color-3">
        {`View all news: `}
      </div>
      {!!_.get(cats, 'length') ?
        <>
          <Grid >
          {
            _.castArray(_.get(_.chunk(cats, limit), pageIndex - 1) || []).map((cat, index) => {
              return (
                <Grid.Column key={index} mobile={16} tablet={16} computer={16} className="col grid">
                  <NewsCard1 cat={cat} onClick={() => onClick(cat)} />
                </Grid.Column>
              )
            })
          }
          </Grid>
          {
            (totalPages > 1) &&
            <div className="paging">
              <Pagination
                totalPages={totalPages}
                boundaryRange={0}
                ellipsisItem={null}
                siblingRange={1}
                activePage={pageIndex}
                
                firstItem={null}
                lastItem={null}
                pointing
                secondary

                onPageChange={(e, { activePage }) => {
                  $page(activePage);
                  $pageIndex(activePage);
                }}
              />
            </div>
          }
        </>:
        <div style={{margin: '24px 0px'}}>
          <NotFound />
        </div>
      }
    </div>
  )
};

export default styled(NewsList)`
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0 15px !important;
  }
  > .title {
    font-weight: 600;
    margin-bottom: 0px;
    padding-left: 10px;
    padding-top: 10px;
    .found {
      font-weight: 400;
      color: #424242;
      margin-left: 10px;
    }
  }
  .ui.grid > .col.column.grid {
    @media (max-width: ${({ theme }) => theme.mobile}) {
      width: 100% !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .card {
      width: 100%;
      margin-bottom: 5px !important;
      margin-top: 0 !important;
    }
    &:first-child {
      .card {
        @media (max-width: ${({ theme }) => theme.mobile}) {
          margin-top: 15px !important;
        }
      }
    }
  }
  .paging {
    width: 100%;
    display: flex;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      justify-content: center;
    }
    justify-content: center;
    padding: 14px;
    .menu {
      .item.active, .item.active:hover {
        color: #005f64 !important;
        border-color: #005f64 !important;
      }
    }
  }
`;
